<template>
  <v-row>
    <v-col
      cols="12"
      class="text-right pb-0 mt-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'savings.clients_create_campaign' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Create a Campaign
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-row class="mt-2 mx-2 pt-0">
          <v-col
            cols="12" 
            class="px-4 pt-2 mt-1 mb-0 pb-0"
          >
            <v-select
              v-model="selectedCampaignFilter"
              outlined
              dense
              :items="campaign_filter"
              label="Filter Campaigns By"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getCampaigns()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ requestedCampaigns.length }}) List Of Campaigns</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <h3 class="ml-3">Total Cash Saved: {{ savings_total }} F CFA  </h3>
        <v-data-table
          :headers="headers"
          :items="requestedCampaigns"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.name`]="{item}">
            <router-link :to="'/savings/clients/campaign/'+item.id" >
              <div class="d-flex flex-column" style="cursor: pointer">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}: {{ item.name }}</span>
                <small v-if="item.target_date && item.target_amount">Type: <b>Date & Amount</b> at {{ item.percentage_completed }} Completion</small>
                <small v-else-if="item.target_amount">Type: <b>Amount</b> at {{ item.percentage_completed }} Completion</small>
                <small v-else-if="item.target_date">Type: <b>Date</b> at {{ item.percentage_completed }} Completion</small>
                <small v-else>Type: <b>Undefined</b>  at --- Completion</small>
              </div>
            </router-link>
          </template>
          <template #[`item.description`]="{item}">
            {{ item.description }}
          </template>

          <template #[`item.saved_amount`]="{item}">
            {{ item.saved_amount }} F CFA
          </template>

          <template #[`item.target_amount`]="{item}">
            {{ item.target_amount }} F CFA - {{ item.target_date }}
          </template>

          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.phone_number`]="{item}">
            <a  v-if="item.phone_number && item.phone_number.length === '9'"
                :href="'https://api.whatsapp.com/send?phone=+237'+item.phone_number+'/&text=Hello *'+item.username+'* 💫. Here\'s your PettyCash campaign update:- Your campaign *'+item.name+'*, %0a '+item.description+', %0a Has *'+item.saved_amount+' Current Savings*, %0a At *'+item.percentage_completed+' Completed.* %0a Target: '+ item.target_amount + 'F CFA - '+item.target_date+'. %0a We encourage you to keep saving with *PettyCash*'" 
                target="_blank"
                style="text-decoration: none"
            >
              <v-btn class="success" elevation="6" icon small>
                <font-awesome-icon icon="fa-solid fa-comments"/>
              </v-btn>
            </a>
            <a v-else
                :href="'https://api.whatsapp.com/send?phone=+237'+item.phone_number+'/&text=Hello *'+item.username+'* 💫. Here\'s your PettyCash campaign update:- Your campaign *'+item.name+'*, %0a '+item.description+', %0a Has *'+item.saved_amount+' Current Savings*, %0a At *'+item.percentage_completed+' Completed.* %0a Target: '+ item.target_amount + 'F CFA - '+item.target_date+'. %0a We encourage you to keep saving with *PettyCash*'" 
                target="_blank"
                style="text-decoration: none"
            >
              <v-btn class="success" elevation="6" icon small>
                <font-awesome-icon icon="fa-solid fa-comments"/>
              </v-btn>
            </a>
          </template>

          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Ongoing: 'primary',
      Completed: 'success',
      Interrupted: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Amount Saved', value: 'saved_amount' },
        { text: 'Target', value: 'target_amount' },
        { text: 'Status', value: 'status' },
        { text: 'Chat', value: 'phone_number' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      selectedCampaignFilter: "All",
      campaign_filter: ["All", "Ongoing", "Completed", "Interrupted"],
      requestedCampaigns: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,
      savings_total: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Campaigns"
  },

  methods:{
    async getCampaigns(){
      if (this.selectedCampaignFilter === ''){
        this.selectedCampaignFilter = 'All'
      }
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }

      this.loading = true
      await axios
      .get('/api/v1/manager/get/campaigns/'+this.selectedCampaignFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
      .then(response => {
        this.requestedCampaigns = response.data["campaigns"]  // get the data and fill into campaigns
        this.savings_total = response.data["total_saved"]  // get the data and fill into campaigns
        console.log(this.requestedCampaigns)
      })
      .catch(error => {
        if (error.response){
          this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
        }else{
          this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
        }
      })
      this.loading = false
    },
  },
}
</script>
